const data = {

  countries:[
    {label: 'Afghanistan', name: 'AF'},
    {label: 'Åland Islands', name: 'AX'},
    {label: 'Albania', name: 'AL'},
    {label: 'Algeria', name: 'DZ'},
    {label: 'American Samoa', name: 'AS'},
    {label: 'AndorrA', name: 'AD'},
    {label: 'Angola', name: 'AO'},
    {label: 'Anguilla', name: 'AI'},
    {label: 'Antarctica', name: 'AQ'},
    {label: 'Antigua and Barbuda', name: 'AG'},
    {label: 'Argentina', name: 'AR'},
    {label: 'Armenia', name: 'AM'},
    {label: 'Aruba', name: 'AW'},
    {label: 'Australia', name: 'AU'},
    {label: 'Austria', name: 'AT'},
    {label: 'Azerbaijan', name: 'AZ'},
    {label: 'Bahamas', name: 'BS'},
    {label: 'Bahrain', name: 'BH'},
    {label: 'Bangladesh', name: 'BD'},
    {label: 'Barbados', name: 'BB'},
    {label: 'Belarus', name: 'BY'},
    {label: 'Belgium', name: 'BE'},
    {label: 'Belize', name: 'BZ'},
    {label: 'Benin', name: 'BJ'},
    {label: 'Bermuda', name: 'BM'},
    {label: 'Bhutan', name: 'BT'},
    {label: 'Bolivia', name: 'BO'},
    {label: 'Bosnia and Herzegovina', name: 'BA'},
    {label: 'Botswana', name: 'BW'},
    {label: 'Bouvet Island', name: 'BV'},
    {label: 'Brazil', name: 'BR'},
    {label: 'British Indian Ocean Territory', name: 'IO'},
    {label: 'Brunei Darussalam', name: 'BN'},
    {label: 'Bulgaria', name: 'BG'},
    {label: 'Burkina Faso', name: 'BF'},
    {label: 'Burundi', name: 'BI'},
    {label: 'Cambodia', name: 'KH'},
    {label: 'Cameroon', name: 'CM'},
    {label: 'Canada', name: 'CA'},
    {label: 'Cape Verde', name: 'CV'},
    {label: 'Cayman Islands', name: 'KY'},
    {label: 'Central African Republic', name: 'CF'},
    {label: 'Chad', name: 'TD'},
    {label: 'Chile', name: 'CL'},
    {label: 'China', name: 'CN'},
    {label: 'Christmas Island', name: 'CX'},
    {label: 'Cocos (Keeling) Islands', name: 'CC'},
    {label: 'Colombia', name: 'CO'},
    {label: 'Comoros', name: 'KM'},
    {label: 'Congo', name: 'CG'},
    {label: 'Congo, The Democratic Republic of the', name: 'CD'},
    {label: 'Cook Islands', name: 'CK'},
    {label: 'Costa Rica', name: 'CR'},
    {label: 'Cote D\'Ivoire', name: 'CI'},
    {label: 'Croatia', name: 'HR'},
    {label: 'Cuba', name: 'CU'},
    {label: 'Cyprus', name: 'CY'},
    {label: 'Czech Republic', name: 'CZ'},
    {label: 'Denmark', name: 'DK'},
    {label: 'Djibouti', name: 'DJ'},
    {label: 'Dominica', name: 'DM'},
    {label: 'Dominican Republic', name: 'DO'},
    {label: 'Ecuador', name: 'EC'},
    {label: 'Egypt', name: 'EG'},
    {label: 'El Salvador', name: 'SV'},
    {label: 'Equatorial Guinea', name: 'GQ'},
    {label: 'Eritrea', name: 'ER'},
    {label: 'Estonia', name: 'EE'},
    {label: 'Ethiopia', name: 'ET'},
    {label: 'Falkland Islands (Malvinas)', name: 'FK'},
    {label: 'Faroe Islands', name: 'FO'},
    {label: 'Fiji', name: 'FJ'},
    {label: 'Finland', name: 'FI'},
    {label: 'France', name: 'FR'},
    {label: 'French Guiana', name: 'GF'},
    {label: 'French Polynesia', name: 'PF'},
    {label: 'French Southern Territories', name: 'TF'},
    {label: 'Gabon', name: 'GA'},
    {label: 'Gambia', name: 'GM'},
    {label: 'Georgia', name: 'GE'},
    {label: 'Germany', name: 'DE'},
    {label: 'Ghana', name: 'GH'},
    {label: 'Gibraltar', name: 'GI'},
    {label: 'Greece', name: 'GR'},
    {label: 'Greenland', name: 'GL'},
    {label: 'Grenada', name: 'GD'},
    {label: 'Guadeloupe', name: 'GP'},
    {label: 'Guam', name: 'GU'},
    {label: 'Guatemala', name: 'GT'},
    {label: 'Guernsey', name: 'GG'},
    {label: 'Guinea', name: 'GN'},
    {label: 'Guinea-Bissau', name: 'GW'},
    {label: 'Guyana', name: 'GY'},
    {label: 'Haiti', name: 'HT'},
    {label: 'Heard Island and Mcdonald Islands', name: 'HM'},
    {label: 'Holy See (Vatican City State)', name: 'VA'},
    {label: 'Honduras', name: 'HN'},
    {label: 'Hong Kong', name: 'HK'},
    {label: 'Hungary', name: 'HU'},
    {label: 'Iceland', name: 'IS'},
    {label: 'India', name: 'IN'},
    {label: 'Indonesia', name: 'ID'},
    {label: 'Iran, Islamic Republic Of', name: 'IR'},
    {label: 'Iraq', name: 'IQ'},
    {label: 'Ireland', name: 'IE'},
    {label: 'Isle of Man', name: 'IM'},
    {label: 'Israel', name: 'IL'},
    {label: 'Italy', name: 'IT'},
    {label: 'Jamaica', name: 'JM'},
    {label: 'Japan', name: 'JP'},
    {label: 'Jersey', name: 'JE'},
    {label: 'Jordan', name: 'JO'},
    {label: 'Kazakhstan', name: 'KZ'},
    {label: 'Kenya', name: 'KE'},
    {label: 'Kiribati', name: 'KI'},
    {label: 'Korea, Democratic People\'S Republic of', name: 'KP'},
    {label: 'Korea, Republic of', name: 'KR'},
    {label: 'Kuwait', name: 'KW'},
    {label: 'Kyrgyzstan', name: 'KG'},
    {label: 'Lao People\'S Democratic Republic', name: 'LA'},
    {label: 'Latvia', name: 'LV'},
    {label: 'Lebanon', name: 'LB'},
    {label: 'Lesotho', name: 'LS'},
    {label: 'Liberia', name: 'LR'},
    {label: 'Libyan Arab Jamahiriya', name: 'LY'},
    {label: 'Liechtenstein', name: 'LI'},
    {label: 'Lithuania', name: 'LT'},
    {label: 'Luxembourg', name: 'LU'},
    {label: 'Macao', name: 'MO'},
    {label: 'Macedonia, The Former Yugoslav Republic of', name: 'MK'},
    {label: 'Madagascar', name: 'MG'},
    {label: 'Malawi', name: 'MW'},
    {label: 'Malaysia', name: 'MY'},
    {label: 'Maldives', name: 'MV'},
    {label: 'Mali', name: 'ML'},
    {label: 'Malta', name: 'MT'},
    {label: 'Marshall Islands', name: 'MH'},
    {label: 'Martinique', name: 'MQ'},
    {label: 'Mauritania', name: 'MR'},
    {label: 'Mauritius', name: 'MU'},
    {label: 'Mayotte', name: 'YT'},
    {label: 'Mexico', name: 'MX'},
    {label: 'Micronesia, Federated States of', name: 'FM'},
    {label: 'Moldova, Republic of', name: 'MD'},
    {label: 'Monaco', name: 'MC'},
    {label: 'Mongolia', name: 'MN'},
    {label: 'Montserrat', name: 'MS'},
    {label: 'Morocco', name: 'MA'},
    {label: 'Mozambique', name: 'MZ'},
    {label: 'Myanmar', name: 'MM'},
    {label: 'Namibia', name: 'NA'},
    {label: 'Nauru', name: 'NR'},
    {label: 'Nepal', name: 'NP'},
    {label: 'Netherlands', name: 'NL'},
    {label: 'Netherlands Antilles', name: 'AN'},
    {label: 'New Caledonia', name: 'NC'},
    {label: 'New Zealand', name: 'NZ'},
    {label: 'Nicaragua', name: 'NI'},
    {label: 'Niger', name: 'NE'},
    {label: 'Nigeria', name: 'NG'},
    {label: 'Niue', name: 'NU'},
    {label: 'Norfolk Island', name: 'NF'},
    {label: 'Northern Mariana Islands', name: 'MP'},
    {label: 'Norway', name: 'NO'},
    {label: 'Oman', name: 'OM'},
    {label: 'Pakistan', name: 'PK'},
    {label: 'Palau', name: 'PW'},
    {label: 'Palestinian Territory, Occupied', name: 'PS'},
    {label: 'Panama', name: 'PA'},
    {label: 'Papua New Guinea', name: 'PG'},
    {label: 'Paraguay', name: 'PY'},
    {label: 'Peru', name: 'PE'},
    {label: 'Philippines', name: 'PH'},
    {label: 'Pitcairn', name: 'PN'},
    {label: 'Poland', name: 'PL'},
    {label: 'Portugal', name: 'PT'},
    {label: 'Puerto Rico', name: 'PR'},
    {label: 'Qatar', name: 'QA'},
    {label: 'Reunion', name: 'RE'},
    {label: 'Romania', name: 'RO'},
    {label: 'Russian Federation', name: 'RU'},
    {label: 'RWANDA', name: 'RW'},
    {label: 'Saint Helena', name: 'SH'},
    {label: 'Saint Kitts and Nevis', name: 'KN'},
    {label: 'Saint Lucia', name: 'LC'},
    {label: 'Saint Pierre and Miquelon', name: 'PM'},
    {label: 'Saint Vincent and the Grenadines', name: 'VC'},
    {label: 'Samoa', name: 'WS'},
    {label: 'San Marino', name: 'SM'},
    {label: 'Sao Tome and Principe', name: 'ST'},
    {label: 'Saudi Arabia', name: 'SA'},
    {label: 'Senegal', name: 'SN'},
    {label: 'Serbia and Montenegro', name: 'CS'},
    {label: 'Seychelles', name: 'SC'},
    {label: 'Sierra Leone', name: 'SL'},
    {label: 'Singapore', name: 'SG'},
    {label: 'Slovakia', name: 'SK'},
    {label: 'Slovenia', name: 'SI'},
    {label: 'Solomon Islands', name: 'SB'},
    {label: 'Somalia', name: 'SO'},
    {label: 'South Africa', name: 'ZA'},
    {label: 'South Georgia and the South Sandwich Islands', name: 'GS'},
    {label: 'Spain', name: 'ES'},
    {label: 'Sri Lanka', name: 'LK'},
    {label: 'Sudan', name: 'SD'},
    {label: 'Surilabel', name: 'SR'},
    {label: 'Svalbard and Jan Mayen', name: 'SJ'},
    {label: 'Swaziland', name: 'SZ'},
    {label: 'Sweden', name: 'SE'},
    {label: 'Switzerland', name: 'CH'},
    {label: 'Syrian Arab Republic', name: 'SY'},
    {label: 'Taiwan, Province of China', name: 'TW'},
    {label: 'Tajikistan', name: 'TJ'},
    {label: 'Tanzania, United Republic of', name: 'TZ'},
    {label: 'Thailand', name: 'TH'},
    {label: 'Timor-Leste', name: 'TL'},
    {label: 'Togo', name: 'TG'},
    {label: 'Tokelau', name: 'TK'},
    {label: 'Tonga', name: 'TO'},
    {label: 'Trinidad and Tobago', name: 'TT'},
    {label: 'Tunisia', name: 'TN'},
    {label: 'Turkey', name: 'TR'},
    {label: 'Turkmenistan', name: 'TM'},
    {label: 'Turks and Caicos Islands', name: 'TC'},
    {label: 'Tuvalu', name: 'TV'},
    {label: 'Uganda', name: 'UG'},
    {label: 'Ukraine', name: 'UA'},
    {label: 'United Arab Emirates', name: 'AE'},
    {label: 'United Kingdom', name: 'GB'},
    {label: 'United States', name: 'US'},
    {label: 'United States Minor Outlying Islands', name: 'UM'},
    {label: 'Uruguay', name: 'UY'},
    {label: 'Uzbekistan', name: 'UZ'},
    {label: 'Vanuatu', name: 'VU'},
    {label: 'Venezuela', name: 'VE'},
    {label: 'Viet Nam', name: 'VN'},
    {label: 'Virgin Islands, British', name: 'VG'},
    {label: 'Virgin Islands, U.S.', name: 'VI'},
    {label: 'Wallis and Futuna', name: 'WF'},
    {label: 'Western Sahara', name: 'EH'},
    {label: 'Yemen', name: 'YE'},
    {label: 'Zambia', name: 'ZM'},
    {label: 'Zimbabwe', name: 'ZW'}
  ]
};

export default data;
