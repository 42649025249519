import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
// import isEmpty from 'lodash/isEmpty';
// import { getBackLink } from '../../helpers/userManager';
import { isGlobalAdmin, isTravelAgencyAdmin } from '../../helpers/user';

import AgencyForm from './agencyForm';
import CompanyForm from './companyForm';
import BlockAccount from '../../containers/AccountManager/BlockAccount';
import Block from '../../styles/images/Block.svg';
// import LoadingIcon from '../../components/common/loadingIcon';

export class AccountFormContainer extends React.Component {
  static contextTypes = {
    i18n: PropTypes.object
  };

  static propTypes = {
    organizationLoading: PropTypes.bool,
    organization: PropTypes.object,
    currentUser: PropTypes.object,
    formLoading: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string.isRequired,
    resetForm: PropTypes.func.isRequired,
    saveOrganization: PropTypes.func.isRequired,
    cancelForm: PropTypes.func.isRequired,
    isEdit: PropTypes.bool
  };

  get sideColumn () {
    if (this.props.isEdit) {
      if(["CA"].indexOf(this.props.currentUser.roleName) === -1){
        return (
          <div className='additional-section editTravelerSection account-form-class' style={{zIndex:'0'}}>
            <ul className='options-list'>
              <li>
                <BlockAccount organization={this.props.organization} />
              </li>
            </ul>
          </div>
        );
      }else{
        return <></>
      }


    }
  }

  render () {
    const { l } = this.context.i18n;
    const { organization, currentUser: { roleName }, isEdit } = this.props;

    let blockIcon;
    if (organization && organization.status === 'blocked') {
      blockIcon = (<img className='block-logo-header' src={Block} alt='block'/>);
    }

    let breadcrumbs;
    let accountForm;
    if (isGlobalAdmin(roleName)) {
      accountForm = <AgencyForm {...this.props} cancelBtnLink='/' isEdit={this.props.isEdit} />;
      breadcrumbs = (
        <ul className='breadcrumb pdg15'>
          <li><Link to='/accounts'>{l('Accounts')}</Link></li>
          {isEdit
            ? (
            <li>{blockIcon}{organization.name}</li>)
            : (
              <li>{l('Add Agency')}</li>
              )}
        </ul>
      );
    } else if(["CA","CU","TAA"].indexOf(this.props.currentUser.roleName) !== -1) {
      accountForm = <CompanyForm {...this.props} cancelBtnLink='/' isEdit={this.props.isEdit} />;
      breadcrumbs = (
        <ul className='breadcrumb users-breadcrumb'>
          <li><Link to={'/companies'}>{l('Companies')}</Link></li>
          {isEdit
            ? (
            <li>{blockIcon}{organization.name}</li>)
            : (
            <li>{l('Add Company')}</li>
              )}
        </ul>
      );
    }

    return (
      <div className='min-container'>
        <div className='head-row'>
          {breadcrumbs}
        </div>
        <div className='holder accounts-form-holder' >
          <div className='side-block' style={{zIndex:'1'}}>
            {accountForm}
          </div>
          {this.sideColumn}
        </div>
      </div>
    );
  }
}
export default AccountFormContainer;
