import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactTooltip from 'react-tooltip';

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useLocation, matchPath, withRouter  } from 'react-router';
import Header from '../../../../containers/Header/Header';
import HomeIcon from '../../../../components/common/svgIcon/HomeIcon';
import TravelerIcon from '../../../../components/common/svgIcon/TravelerIcon';
import FlightIcon from '../../../../components/common/svgIcon/FlightIcon';
import HealthIcon from '../../../../components/common/svgIcon/HealthIcon';
import WorldhubIcon from '../../../../components/common/svgIcon/WorldhubIcon';
import SettingIcon from '../../../../components/common/svgIcon/SettingIcon';
import logo from '../../../../styles/images/logo.png';
import Logout from '../../../../containers/Logout/Logout';
import UsersIcon from '../../../../components/common/svgIcon/UsersIcon';
import CompanyIcon from '../../../../components/common/svgIcon/CompanyIcon';
import FilterIcon from '../../../../components/common/svgIcon/FilterIcon';
import NotifIcon from '../../../../components/common/svgIcon/NotifIcon';
import TravelersIcon from '../../../../components/common/svgIcon/TravelersIcon';
import WorldWatchWhite from '../../../../styles/images/WorldWatchWhite.png';
import SidebarSecurityIcon from '../../../../components/common/svgIcon/SidebarSecurityIcon';
import POIIcon from '../../../../components/common/svgIcon/POIIcon';

import Pin from '../../../../styles/images/Pin.svg';
import Unpin from '../../../../styles/images/Unpin.svg';
import { Link } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { fade } from '@material-ui/core/styles/colorManipulator';

import Collapse from "@material-ui/core/Collapse";
import { setSidebarOpen, setSidebarClose, pinSidebar, unpinSidebar } from '../../../../helpers/sidebarActions';

import ROLES  from '../../../../acl/roles'

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'none'
  },
  appMenu: {
    width: '100%',
    height: '843px'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 34
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    justifyContent: 'space-between',
    // transition: theme.transitions.create('width', {
    //   easing: theme.transitions.easing.easeInOut,
    //   duration: theme.transitions.duration.enteringScreen
    // })
  },
  drawerClose: {
    justifyContent: 'space-between',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: 'flex',
    backgroundColor: '#154690',
    alignItems: 'center',
    justifyContent: 'center',


    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,

  },

  nested: {
    paddingLeft: '40px',



  },
  nestedclosed: {
    paddingLeft: '22px',

  },
  listopenpad: {
    paddingTop: '10px',
    paddingBottom: '10px',
    // paddingLeft: '22px',
    borderLeft: '5px solid',
    borderLeftColor: '#154690',

  },

  listopenpadlas: {
    paddingTop: '10px',
    paddingBottom: '10px',
    // paddingLeft: '22px',
    backgroundColor: '#154690',
    color: 'white',
    borderRadius: '0px 0px 0px 14px',
    borderTop: '1px solid white',
    left: 0,
    width: '-webkit-fill-available',
    // opacity:0.7,

  },
  listclosepad: {
    paddingTop: '10px',
    paddingBottom: '10px',

  },

  menuItem: {
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '22px',
    color: 'white',
    // opacity:0.8,
    // backgroundColor: rg
    fontSize: 14,
    borderLeft: '5px solid',
    borderLeftColor: 'rgba(0,0,0, 0)',


    '&:focus': {
      borderLeft: '5px solid',
      borderLeftColor: 'white',
      backgroundColor: '#006cba',
      opacity:1,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    }
  },

  miniMenuItem: {
    color: 'white',
    margin: '10px 0',
    fontSize: 14,
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    }
  },
  miniIcon: {
    margin: '0 auto',
    color: 'white',
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.5)
    },
    minWidth: '24px'
  },
  bg1546: {
    backgroundColor: '#154690',
    color: 'white',

  },
  colorWhite: { color: 'white' },

  iconMinWidth: { minWidth: '35px' },
  fw800: { fontWeight: 800 },
  activeMenu: {
    borderLeft: '5px solid',
    borderLeftColor: 'white',
    backgroundColor: '#006cba',
    opacity:1,

  }

}));

const getSubItems = (userRole, currentLocation) => {
  let subItems = [];
  if (userRole === ROLES.GLOBAL_ADMIN) {

    let accountsSubItem = {
      name: 'Accounts',
      icon: <CompanyIcon style={{ paddingTop: '2px' }} />,
      link: '/accounts',
    }
    let usersSubItem = {
      name: 'Global Administrators',
      icon: <UsersIcon />,
      link: '/users',
    }
    subItems.push(accountsSubItem);
    subItems.push(usersSubItem);
  } else if (userRole === ROLES.TRAVEL_AGENCY_ADMIN) {

    let companiesSubItem = {
      name: 'Companies',
      icon: <CompanyIcon style={{ paddingTop: '2px' }} />,
      link: '/companies',
    }
    let usersSubItem = {
      name: 'Agency Users',
      icon: <UsersIcon />,
      link: '/users',
    }

    subItems.push(companiesSubItem);
    subItems.push(usersSubItem);

  } else if (userRole === ROLES.COMPANY_ADMIN) {

    // let usersSubItem = {
    //   name: 'Company Users',
    //   icon: <UsersIcon />,
    //   link: '/users',
    // }
      let companiesSubItem = {
      name: 'Companies',
      icon: <CompanyIcon style={{ paddingTop: '2px' }} />,
      link: '/companies',
    }
    // subItems.push(usersSubItem);
    subItems.push(companiesSubItem);
  }
  if (userRole !== ROLES.GLOBAL_ADMIN) {

    let travelersSubItem = {
      name: 'Travelers',
      icon: <TravelersIcon />,
      routeMatchStrings: ['/traveler/:id/edit','/add/traveler','/import/traveler'],
      link: '/travelers-list',
    }
    let filterSubItem = {
      name: 'Filters',
      icon: <FilterIcon />,
      link: '/settings/global-filter',
    }

    let notificationSubItem = {
      name: 'Notifications',
      icon: <NotifIcon />,
      link: '/settings/notifications',
    }
    let POISubItem = {
      name: 'POI',
      icon: <POIIcon style={{cursor:'not-allowed',position:'absolute',left:'-3px',}}/>,
      disabled: true


    }

    subItems.push(travelersSubItem);
    subItems.push(filterSubItem);
    subItems.push(notificationSubItem);
    subItems.push(POISubItem);
  }

  subItems.forEach( (subitem, idx) => {
    subitem.id = 'sidebar-settings-' + idx;
    subitem.active = false;
    let routeMatchStrings = subitem.routeMatchStrings ? subitem.routeMatchStrings : [subitem.link];
    let routeMatched = routeMatchStrings.find( (routeMatchString) =>
      ( matchPath(
          location.pathname,
          {path: routeMatchString, strict: true, sensitive: true })
      )
    );
    if (routeMatched) { subitem.active = true;}
    else {
      if ( matchPath(
          currentLocation.pathname, {path: subitem.link, strict: true, sensitive: true })) {

        subitem.active = true;
      }
    }



  });

  return subItems;
}

const SettingsTabs = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const open = props.sidebarState.isOpened || props.sidebarState.isSidebarPinned;
  const isSidebarPinned = props.sidebarState.isSidebarPinned;
  const location = useLocation();

  const { userRole } = props;

  const itemjson = [
    // {
    //   id: 1,
    //   name: 'Home',
    //   link: '/home',
    //   icon: <HomeIcon />,
    // },
    {
      id: 2,
      name: 'Travelers',
      link: '/active-travelers/locations',
      routeMatchStrings: ['/active-travelers/:mapView', '/traveler/:id'],
      icon: <TravelerIcon />,
    },
    {
      id: 3,
      name: 'FlightBoard',
      link: '/flights-status',
      icon: <FlightIcon />,
    },
    {
      id: 4,
      name: 'Health',
      link: '/health',
      icon: <HealthIcon />,
    },
    {
      id: 5,
      name: 'Security',
      link: '/travel-advisories',
      routeMatchStrings: ['/travel-advisories/:country?','/travelerad/:id','/travelerad/:id/reservations','/travelerad/:id/maps','/travelerad/:id/flight-status',
        '/travelerad/:id/travel-advisories','/travelerad/:id/contact'],
      icon: <SidebarSecurityIcon />,
    },
    {
      id: 6,
      name: 'WorldHub',
      link: '/worldhub',
      icon: <WorldhubIcon />,
    },
    {
      id: 7,
      name: 'Settings',
      icon: <SettingIcon />,
      link: '/#Settings',
      expanded: false,
      subitems: getSubItems(userRole, location)
    },
    {
      id: 8,
      name: <Header />,
      icon: <Logout />,
      link: '#',
    },

  ];

  itemjson.forEach(  (item) => {
    if (item.name !== 'Settings') {
      let routeMatchStrings = item.routeMatchStrings ? item.routeMatchStrings : [item.link];
      item.active = false;
      let routeMatched = routeMatchStrings.find( (routeMatchString) =>
        ( matchPath(
          location.pathname,
          {path: routeMatchString, strict: true, sensitive: true })
        )
      );
      if (routeMatched) item.active = true;
    }
  });


  let linkedItem = itemjson.find( (item) => item.active );
  if ( props.match.path === '/traveler/:id/edit'){
    linkedItem.active = false;
    let settingsItem = itemjson.find( (item) => item.name === 'Settings');
    settingsItem.expanded = true;
  }
  else{

  }

  if (!linkedItem) {
    let settingsItem = itemjson.find( (item) => item.name === 'Settings');
    settingsItem.expanded = true;
  }

  const [settingsToggle, setSettingsToggle] = React.useState( null );

  const handleDrawerOpen = () => {
    props.setSidebarOpen();
  };

  const handleDrawerClose = () => {
    if (!props.sidebarState.isSidebarPinned) {
      props.setSidebarClose();
    }
  };

  const handlePinClosed = () => {
    props.unpinSidebar();
    handleDrawerClose();
  };

  const handlePinOpen = () => {
    props.pinSidebar();
    handleDrawerOpen();
  };

  const handleClick = (item) => {
    if ( item.name === 'Settings') {
      if (settingsToggle === null) {
        setSettingsToggle(!item.expanded);
      } else {
        setSettingsToggle(!settingsToggle);
      }
    } else {
      setSettingsToggle( false );
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
            [classes.bg1546]: true,
          },
          ),
        }}
        // onMouseEnter={handleDrawerOpen}
        // onMouseLeave={handleDrawerClose}
      >
        <div>
          <div className={classes.toolbar}
            style={{
              paddingBottom: '20px',
              paddingTop: '35px'
            }}>
            {(!open)
              ? <img style={{
                backgroundColor: '#154690',
                marginRight: '2px',

              }} src={logo} alt="Worldwatch" width='40px' />
              : <img style={{ backgroundColor: '#154690' }} src={WorldWatchWhite} alt="Worldwatch" width='186.8px'
                height='34.05px ' />
            }
          </div>


          <List>
            {itemjson.map(item => {
              return (
                <div key={item.id}>
                  {item.subitems != null ? (
                    <div key={item.id}>

                      <ListItem classes={{ root: classes.menuItem }}
                      className={
                          clsx({
                            [classes.nested]: open,
                            [classes.nestedclosed]: !open,
                          })
                        }
                        button
                        key={item.id}
                        onClick={handleClick.bind(
                          this,
                          item,
                        )}

                      >


                        <ListItemIcon className={clsx({
                          [classes.iconMinWidth]: open,

                        })}
                          data-tip data-for={item.name}
                          style={{ color: 'white' }}> {item.icon} </ListItemIcon>

                        {!open?<ReactTooltip id={item.name} aria-haspopup='true' place="bottom" textColor='black' backgroundColor='lightgrey' multiline={true} data-html={true}>
                          <span className={"extraClassPop"}>{item.name}
                          </span></ReactTooltip>:''}

                        <ListItemText
                          primary={<span style={{ fontWeight: 'bold'}}>{item.name}</span>}
                        />

                      </ListItem>

                      <Collapse
                        key={itemjson.id}
                        component="li"
                        in={settingsToggle === null ? item.expanded : settingsToggle}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List disablePadding style={{ backgroundColor: '#1B355D' }}>
                          {item.subitems.map(
                            sitem => {
                              let style;

                              sitem.name === 'POI' ? style={cursor:'not-allowed',fontWeight:900,textDecoration: 'none'}:style={fontWeight:900,textDecoration: 'none'}
                              return (
                                <Link key={sitem.id} to={sitem.link}>
                                  <ListItem classes={{ root: classes.menuItem }}
                                    button
                                    key={
                                      sitem.id
                                    }
                                    disabled={sitem.disabled?true:false}
                                    className={
                                      clsx({
                                        [classes.nested]: open,
                                        [classes.nestedclosed]: !open,
                                        // [classes.activeMenu]: matchPath( location, { path: sitem.link, strict: true, sensitive: true} ),
                                        [classes.activeMenu]: sitem.active
                                      })
                                    }
                                  >
                                    <ListItemIcon className={clsx({
                                      [classes.iconMinWidth]: open,

                                    })}
                                      data-tip data-for={sitem.name}
                                      style={{ color: 'white' }}> <span style={{position:'absolute', top:'16px'}}>{sitem.icon} </span></ListItemIcon>

                                    {!open?<ReactTooltip id={sitem.name} aria-haspopup='true' place="bottom" textColor='black' backgroundColor='lightgrey' multiline={true} data-html={true}>
                          <span className={"extraClassPop"}>{sitem.name}
                          </span></ReactTooltip>:''}

                                    <ListItemText
                                      key={
                                        sitem.id
                                      }
                                      primary={
                                        <span style={{ fontWeight: 'bold' }}>{sitem.name}</span>
                                      }
                                      style={style}
                                    />
                                  </ListItem>
                                </Link>

                              );
                            }
                          )}
                        </List>
                      </Collapse>{' '}
                    </div>
                  ) : userRole === ROLES.GLOBAL_ADMIN && item.link != '#' ? null : (
                      <Link key={item.id} to={item.link}>
                        <ListItem classes={{ root: classes.menuItem }}
                          className={clsx({
                            [classes.listopenpad]: open,
                            [classes.listclosepad]: !open,
                            // [classes.activeMenu]: matchPath(location, { path: item.routeMatchString, strict: true, sensitive: true} ) !== null
                            [classes.activeMenu]: item.active
                          })}
                          button
                          onClick={handleClick.bind(
                            this,
                            item,
                          )}
                          key={item.id}
                        >
                          <ListItemIcon className={clsx({
                            [classes.iconMinWidth]: open,

                          })}
                            data-tip data-for={item.name+'m'}
                            style={{ color: 'white' }}> {item.icon} </ListItemIcon>

                          {!open?<ReactTooltip id={item.name+'m'} aria-haspopup='true' place="bottom" textColor='black' backgroundColor='lightgrey' multiline={true} data-html={true}>
                          <span className={"extraClassPop"}>{item.id == 8?'Log Out':item.name}
                          </span></ReactTooltip>:''}

                          <ListItemText
                            primary={<span style={{ fontWeight: 'bold' }}>{item.name}</span>}
                            style={{ fontWeight: 900 }}
                          />
                        </ListItem>
                      </Link>
                    )}
                </div>
              );
            })}


          </List>
        </div>


        <div
          className={clsx({
            [classes.listopenpadlas]: open,
            [classes.listclosepad]: !open,
          })}
          tabIndex="-1"
          style={{height:'60px',cursor:'pointer'}}
        >
          {(!isSidebarPinned)
            ?
            <div style={{ display: 'flex', paddingLeft:'15px', alignItems:"center" }}>
              <img onClick={handlePinOpen} style={{height:'46px'}} src={Pin} alt=''
                   data-tip data-for='pinmenu'
                className={clsx({ [classes.hide]: isSidebarPinned })} />
              <ReactTooltip id={'pinmenu'} aria-haspopup='true' place="bottom" textColor='black' backgroundColor='lightgrey' multiline={true} data-html={true}>
                          <span className={"extraClassPop"}>{"Pin Menu Open"}
                          </span></ReactTooltip>

              {open && <span style={{fontWeight:900,paddingLeft:'10px'}}>Pin Menu</span>}
            </div>
            : <div style={{ display: 'flex', paddingLeft:'26px' }}> <img onClick={handlePinClosed} src={Unpin}  data-tip data-for='unmenu' /> <div style={{ padding: '10px 0px 0px 17px', fontWeight:900}}><span>Unpin Menu</span></div>
              <ReactTooltip id={'unmenu'} aria-haspopup='true' place="bottom" textColor='black' backgroundColor='lightgrey' multiline={true} data-html={true}>
                          <span className={"extraClassPop"}>{"Unpin Menu"}
                          </span></ReactTooltip>
            </div>
          }

        </div>


      </Drawer>
      <main className={classes.content}>
        {props.children}
      </main>
    </div >
  );

};

const mapStateToProps = (state) => {
  // console.log(state);
  return {
    sidebarState: state.sidebar,
    userRole: state.user.roleName
  }
}

const mapDispatchToProps = dispatch => ({
  pinSidebar: bindActionCreators(pinSidebar, dispatch),
  unpinSidebar: bindActionCreators(unpinSidebar, dispatch),
  setSidebarOpen: bindActionCreators(setSidebarOpen, dispatch),
  setSidebarClose: bindActionCreators(setSidebarClose, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(SettingsTabs));
