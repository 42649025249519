/* eslint-disable max-len */
import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > svg': {
//       margin: theme.spacing(2)
//     }
//   }
// }));

export default function HomeIcon (props) {
  return (
    <SvgIcon {...props}>
    <path d="M21.0923649,11.2706129 L21.0923649,18.5911734 C21.0923649,18.855527 20.9957742,19.0842945 20.8025927,19.2774759 C20.6094112,19.4706574 20.3806437,19.5672481 20.1162902,19.5672481 L14.2598418,19.5672481 L14.2598418,13.7107997 L10.3555428,13.7107997 L10.3555428,19.5672481 L4.49909446,19.5672481 C4.23474089,19.5672481 4.00597337,19.4706574 3.81279192,19.2774759 C3.61961046,19.0842945 3.52301973,18.855527 3.52301973,18.5911734 L3.52301973,11.2706129 C3.52301973,11.2604455 3.52556159,11.2451943 3.53064532,11.2248594 C3.53572904,11.2045245 3.5382709,11.1892733 3.5382709,11.1791059 L12.3076923,3.95005243 L21.0771137,11.1791059 C21.0872812,11.1994408 21.0923649,11.2299431 21.0923649,11.2706129 Z M24.4933753,10.2182823 L23.5478029,11.3468687 C23.4664633,11.4383758 23.3597051,11.4942967 23.2275284,11.5146316 L23.1817749,11.5146316 C23.0495981,11.5146316 22.9428399,11.4790455 22.8615003,11.4078734 L12.3076923,2.60794967 L1.75388428,11.4078734 C1.63187494,11.489213 1.5098656,11.524799 1.38785626,11.5146316 C1.25567947,11.4942967 1.1489213,11.4383758 1.06758174,11.3468687 L0.122009341,10.2182823 C0.0406697804,10.1166079 0.00508372256,9.99714041 0.0152511677,9.8598799 C0.0254186128,9.72261939 0.0813395609,9.61331935 0.183014012,9.53197979 L11.1486036,0.396530359 C11.4739618,0.132176786 11.8603247,0 12.3076923,0 C12.7550599,0 13.1414228,0.132176786 13.4667811,0.396530359 L17.188066,3.50776856 L17.188066,0.533790868 C17.188066,0.391446637 17.2338195,0.274521018 17.3253265,0.183014012 C17.4168335,0.091507006 17.5337591,0.045753503 17.6761033,0.045753503 L20.6043275,0.045753503 C20.7466718,0.045753503 20.8635974,0.091507006 20.9551044,0.183014012 C21.0466114,0.274521018 21.0923649,0.391446637 21.0923649,0.533790868 L21.0923649,6.75626728 L24.4323706,9.53197979 C24.5340451,9.61331935 24.589966,9.72261939 24.6001334,9.8598799 C24.6103009,9.99714041 24.5747148,10.1166079 24.4933753,10.2182823 Z"></path>
    </SvgIcon>
  );
}

// export default function SvgIconsSize () {
//   const classes = useStyles();

//   return (
//     <div className={classes.root}>
//       <HomeIcon fontSize="small" />
//       <HomeIcon />
//       <HomeIcon fontSize="large" />
//       <HomeIcon style={{ fontSize: 40 }} />
//     </div>
//   );
// }
