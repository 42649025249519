import { AbilityBuilder, Ability } from '@casl/ability'
import Roles from './roles';

export const ability = new Ability();

export function updateAbility(ability, user) {
  const { can, cannot, rules } = new AbilityBuilder(Ability);
  if (user.roleName === Roles.GLOBAL_ADMIN ) {
    console.log(Roles.GLOBAL_ADMIN, 'userRole');
    can('view', 'Agency');
    can('manage', 'Agency');
    can('view', 'Company');
    can('manage', 'Company');
    can('view', 'Users');
    can('manage', 'Users');
  } else if (user.roleName === Roles.TRAVEL_AGENCY_ADMIN ) {
    console.log(Roles.TRAVEL_AGENCY_ADMIN, 'userRole');
    can('view', 'Agency');
    can('view', 'Company');
    can('manage', 'Company');
    can('view', 'Users');
    can('manage', 'Users');
  } else if (user.roleName === Roles.TRAVEL_AGENT ) {
    console.log(Roles.TRAVEL_AGENT, 'userRole');
    // TODO: Need to add relevant abilities
  } else if (user.roleName === Roles.COMPANY_ADMIN ) {
    console.log(Roles.TRAVEL_AGENT, 'userRole');
    // TODO: Need to add relevant abilities
  }
  // update the ability object with the new set of rules.
  return ability.update(rules);
}
