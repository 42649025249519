import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import RadioButtonsGroup from '../forms/radioButtonsGroup';
import find from 'lodash/find';
import filter from 'lodash/filter';
import differenceWith from 'lodash/differenceWith';
import { isAdmin } from '../../helpers/user';
// import { TYPE_AGENCY } from '../../helpers/organization';
import { withRouter } from "react-router-dom";

import Close from '../common/svgIcon/Close.svg';
export class SortLevelIdsBlock extends React.Component {
  static contextTypes = {
    i18n: PropTypes.object
  };

  static propTypes = {
    organizations: PropTypes.array.isRequired,
    selected: PropTypes.array,
    isIncluded: PropTypes.bool.isRequired,
    currentUser: PropTypes.object.isRequired,
    organization: PropTypes.object.isRequired,
    userRoleName: PropTypes.string
  };

  constructor () {
    super(...arguments);
    const { l } = this.context.i18n;
    const allOrganizationLabel = this.isCompanyBlock() ? l('All Companies') : l('All Sort Level IDs');
    this.allOrganizationValue = { label: allOrganizationLabel, value: 'all' };

    const someSelected = this.props.selected.length && this.props.selected.length !== this.props.organizations.length;
    this._options = [...this.props.organizations];

    let isAllcompanies = true;
    if (this.props.match.path === "/companies/:organizationId/add/user" || this.props.match.path === '/companies/:organizationId/users/edit/:id' ){
      isAllcompanies = false;
    }

    if(["CA","CU"].indexOf(this.props.currentUser.roleName) !== -1){
      if (this.props.match.path === "/companies/:organizationId/add/user" || this.props.match.path === '/companies/:organizationId/users/edit/:id' ){
        isAllcompanies = false;
      }else{
        isAllcompanies = true;
      }

    }

    this.state = {
      isIncluded: "include",
      checkedOrganizations: someSelected ? this.props.selected : this.props.selected ,
      options: this._options,
      value: someSelected ? '' : isAllcompanies? this.allOrganizationValue:'Select Company'
    };
  }

  get value () {
    const orgArr = this.state.checkedOrganizations ? this.state.checkedOrganizations : [];
    const organisationArray = orgArr
      .filter((item) => {
        return (item && Object.prototype.hasOwnProperty.call(item, 'value'));
      })
      .map((item) => {
        return item.value;
      });
    return {
      isIncluded: this.state.isIncluded,
      checkedOrganizations: organisationArray,
      value: ''
    };
  }

  isCompanyBlock () {
    // const { organization, currentUser } = this.props;

    return true;
    // return (organization && organization.type === TYPE_AGENCY) || (organization && !organization.type && currentUser &&
    // currentUser.roleName && isTravelAgencyAdmin(currentUser.roleName));
  }

  onIncludeStateChange = (val) => {
    this.setState({
      isIncluded: val === 'include'
    });
  }

  onOrganizationChange = (value) => {
    // console.log(value);
    if (!value) {
      return;
    }
    if (value.value === 'all') {
      this._options = [...this.props.organizations];
      this.setState({
        options: this._options,
        checkedOrganizations: [],
        value: this.allOrganizationValue
      });
    } else {
      const values = [].concat(this.state.checkedOrganizations, [value]);
      this._options = filter(this._options, (option) => {
        return option.value !== value.value;
      });
      if (values.length === this.props.organizations.length + 1) {
        // console.log(values.length, this.props.organizations.length);
        // If all options seleted change select's value to all organisations selected
        this._options = [...this.props.organizations];
        let selvalue = ""
        if (this.props.match.path === "/companies/:organizationId/add/user" || this.props.match.path === '/companies/:organizationId/users/edit/:id' ){
          selvalue = isAllcompanies? this.allOrganizationValue:'Select Company'
        }else{
          selvalue= this.allOrganizationValue
        }


        this.setState({
          options: this._options,
          checkedOrganizations: [],
          value: selvalue
        });
      } else {
        this.setState({
          options: this._options,
          checkedOrganizations: values,
          value: ''
        });
      }
    }
  }

  removeOption = (option) => {
    console.log(option);
    // e.preventDefault();
    if (this._options.indexOf(option) === -1) {
      this._options.push(option);
    }
    const values = filter(this.state.checkedOrganizations, (item) => {
      if (item !== undefined) return item.value !== option.value;
    });
    let companyAddEditUserPage = (this.props.match.path === "/companies/:organizationId/add/user" || this.props.match.path === '/companies/:organizationId/users/edit/:id' );

    const selectVal = !values.length ? companyAddEditUserPage?'Select Company':this.allOrganizationValue : '';
    this.setState({
      options: [...this._options],
      checkedOrganizations: values,
      value: selectVal
    });
  }

  get selectedOptions () {
    const { l } = this.context.i18n;
    console.log(this.state.checkedOrganizations);
    if (this.state.checkedOrganizations && this.state.checkedOrganizations.length) {
      return this.state.checkedOrganizations
        .filter((elem) => (elem && Object.prototype.hasOwnProperty.call(elem, 'label')))
        .map((option, index) => {
          return (
            <div className='teg-item' key={index}>
              <div>{option.label}</div>
              <a href='#' title={l('close')} onClick={() => { this.removeOption(option); }}><img src={Close} alt='close'/></a>
            </div>
          );
        });
    }
  }

  render () {

    const { l } = this.context.i18n;
    const { isIncluded } = this.state;
    const premissionsFields = [
      {
        label: l('Exclude'),
        value: 'exclude',
        checked: !isIncluded
      },
      {
        label: l('Include'),
        value: 'include',
        checked: isIncluded
      }
    ];

    // Set options for select, removing already selected options

    let selectsOprions = differenceWith(this._options, this.state.checkedOrganizations);
    // Prepend 'all' option to the selects options
    const isAllOption = find(this._options, (item) => item.value === 'all');
    if (!isAllOption) {
      this._options.unshift(this.allOrganizationValue);
    }
    // Set text for companies or sort level ids
    const isCompany = this.isCompanyBlock();
    let heading = l('Sort Level IDs Access Permissions');
    let placeholderText = l('Select Sort Level ID');

    if (isCompany) {
      heading = l('Access Permissions');
      placeholderText = l('Select Company');
    }

    // Add note when all organizations included or excluded
    // Commenting this out as it is not available in invision
    // let selectNote;
    // if ((!this.state.checkedOrganizations || !this.state.checkedOrganizations.length)) {
    //   const label = isCompany ? 'companies' : 'Sort Level IDs';

    //   if (isIncluded) {
    //     selectNote = <span className='note'>{l(`All ${label} are included`)}</span>;
    //   } else {
    //     selectNote = <span className='note'>{l(`By default all ${label} are excluded`)}</span>;
    //   }
    // }

    if (this.props.match.path === "/companies/:organizationId/add/user" || this.props.match.path === '/companies/:organizationId/users/edit/:id' ){
      // selectsOprions.splice(0,1)
    }

    if (this.props.match.path === "/users/add" || this.props.match.path === '/users/edit/:id' ){
      console.log(this.props.currentUser);

      if(["CA","CU"].indexOf(this.props.currentUser.roleName) === -1){
        if(!this.props.isUser){
          return (<></>)
        }
      }

    }
    console.log(["CA","CU"].indexOf(this.props.currentUser.roleName));
    if(["CA","CU","TAA"].indexOf(this.props.currentUser.roleName) !== -1){
      if (this.props.match.path === "/companies/:organizationId/add/user" || this.props.match.path === '/companies/:organizationId/users/edit/:id' ){
        console.log(selectsOprions);
        selectsOprions =  selectsOprions.filter(key => key.value !== 'all');
        // selectsOprions.splice(selectsOprions.findIndex(({value}) => value == "all"));
        console.log(selectsOprions)
      }

    }



    return (
      <div className='form-area'>
        <h3>{heading}</h3>
        {/*<RadioButtonsGroup*/}
        {/*name='permissions'*/}
        {/*fields={premissionsFields}*/}
        {/*onRadioGroupChange={this.onIncludeStateChange}*/}
        {/*/>*/}
        <div className='permissions-select'>
          <Select
            clearable={false}
            options={selectsOprions}
            value={this.state.value}
            placeholder={placeholderText}
            onChange={this.onOrganizationChange}
            arrowRenderer = {() => (<i class="fa fa-angle-down fa-lg" aria-hidden="true"></i> )}/>
        </div>
        {/* {selectNote} */}
        <div className='bottom-block'>{this.selectedOptions}</div>
      </div>
    );
  }
}


const withRouterAndRef = Wrapped => {
  const WithRouter = withRouter(({ forwardRef, ...otherProps }) => (
    <Wrapped ref={forwardRef} {...otherProps} />
  ))
  const WithRouterAndRef = React.forwardRef((props, ref) => (
    <WithRouter {...props} forwardRef={ref} />
  ))
  const name = Wrapped.displayName || Wrapped.name
  WithRouterAndRef.displayName = `withRouterAndRef(${name})`
  return WithRouterAndRef
}


export default withRouterAndRef(SortLevelIdsBlock);
